import React from "react";
import { Row } from "react-bootstrap";
import { getLanguageObject } from "../../../utils/language";
import usePackageLessons from "./hooks/usePackageLessons";
import SubmitPackageLesson from "./components/SubmitPackageLesson/SubmitPackageLesson";

const PackageLessons = () => {
  const {
    Content,
    handleOpenPackageLessonModal,
    packageLessonModalProps
  } = usePackageLessons();

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            {getLanguageObject().pages.package_lessons.list.title}
          </h4>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleOpenPackageLessonModal();
            }}
          >
            {getLanguageObject().pages.package_lessons.list.add_new}
          </button>
        </div>
        <div className="card-body">
          <Row>{Content}</Row>
        </div>
        <SubmitPackageLesson {...packageLessonModalProps}/>
      </div>
    </>
  );
};

export default PackageLessons;
