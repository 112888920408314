import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getForm } from "../../../../utils/form";
import { loadingToggleAction } from "../../../../../store/actions/AuthActions";
import { createBusinessAction } from "../actions/onboarding";
import { toast } from "react-toastify";

const useOnboarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onboardingFormInputs = [
    {
      field: "name",
      name: "Business Name",
      defaultValue: "",
      placeholder: "Enter your business name",
      required: true,
      type: "text",
    },
    {
      field: "description",
      name: "Business Description",
      defaultValue: "",
      placeholder: "Enter your business description",
      required: true,
      type: "textarea",
    },
  ];

  const { formValues, formErrors } = getForm(onboardingFormInputs);

  const [values, setValues] = useState(formValues);

  const [errors, setErrors] = useState(formErrors);

  function onSubmitBusiness(e) {
    e.preventDefault();

    let error = false;

    Object.keys(values).forEach((formField) => {
      if (!values[formField]) {
        formErrors[formField] = `${formField} is required`;

        error = true;
      }
    });

    setErrors(formErrors);

    if (error) {
      return;
    }

    dispatch(loadingToggleAction(true));

    createBusinessAction({
      values,
      onSuccess: () => {
        window.location.href = "/business-profile";
      },
      onFailure: (text) => {
        toast.error(`❌ ${text} !`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    });
  }

  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: "" });
  };

  return {
    onboardingFormInputs,
    onSubmitBusiness,
    errors,
    handleChangeInput,
    values,
  };
};

export default useOnboarding;
