import axiosInstance from "../AxiosInstance";

export function createBusiness(variables) {
  return axiosInstance.post(``, variables);
}

export function getBusiness() {
  return axiosInstance.get(``);
}

export function updateBusiness(variables) {
  return axiosInstance.put(``, variables);
}

export function updateBusinessPaymentInfo(variables) {
  return axiosInstance.put(`payment-info`, variables);
}

export function getBusinessInfo(id) {
  return axiosInstance.get(`business/${id}/about`);
}

export function updateBusinessInfo(variables) {
  return axiosInstance.put(`info`, variables);
}

export function createBusinessLink(variables) {
  return axiosInstance.post(`link`, variables);
}

export function deleteBusinessLink(variables) {
  return axiosInstance.delete(`link`, { data: variables });
}

export function updateBusinessLocation(variables) {
  return axiosInstance.put(`location`, variables);
}

export function getBusinessClassrooms(id) {
  return axiosInstance.get(`business/${id}/classrooms`);
}

export function getBusinessLessons(id) {
  return axiosInstance.get(`business/${id}/lessons`);
}

export function getBusinessMembers(id) {
  return axiosInstance.get(`business/${id}/members`);
}

export function getBusinessStaff(id) {
  return axiosInstance.get(`business/${id}/staff`);
}

export function getBusinessAmenities(id) {
  return axiosInstance.get(`amenities/business/${id}`);
}

export function updateBusinessAmenities(id, variables) {
  return axiosInstance.patch(`amenities/business/${id}`, variables);
}
