import React, {useContext} from 'react';
import {ThemeContext} from "../../context/ThemeContext";
import {useSelector} from "react-redux";
import Nav from "./nav";
import {Outlet} from "react-router-dom";
import Footer from "./Footer";

const MainLayout = () => {
  const { sidebariconHover } = useContext(ThemeContext);

  const sideMenu = useSelector((state) => state.sideMenu);

  return (
    <>
      <div
        id="main-wrapper"
        className={`show  ${sidebariconHover ? "iconhover-toggle" : ""} ${sideMenu ? "menu-toggle" : ""}`}
      >
        <Nav />
        <div className="content-body">
          <div
            className="container-fluid"
            //style={{ minHeight: window.screen.height - 45 }}
          >
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}


export default MainLayout;
