import React from "react";
import { Row } from "react-bootstrap";
import useMemberships from "./hooks/useMemberships";
import SubmitMembership from "./components/SubmitMembership/SubmitMembership";


const Memberships = () => {
  const { Content, handleOpenSubmitMembershipModal, submitMembershipModalProps } = useMemberships();

  return (
    <>

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">
            {"Üyelikler"}
          </h4>
          <button
            className="btn btn-primary"
            onClick={() => {
              handleOpenSubmitMembershipModal();
            }}
          >
            {"+ Yeni Üyelik Ekle"}
          </button>
        </div>
        <div className="card-body">
          <Row>{Content}</Row>
        </div>
        <SubmitMembership {...submitMembershipModalProps}/>
      </div>
    </>
  );
};

export default Memberships;
