import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GoogleStaticMap from "./GoogleStaticMap";
import useLocationInput from "../hook/useLocationInput";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const LocationInput = ({ defaultLocation, onSelectLocation }) => {
  const { selectedLocation, handleChangeSelectedLocation } = useLocationInput({
    defaultLocation,
    onSelectLocation,
  });

  return (
    <div style={{ padding: "10px" }}>
      <GooglePlacesAutocomplete
        apiKey={API_KEY}
        selectProps={{
          className: "basic-single",
          classNamePrefix: "select",
          value: selectedLocation,
          isClearable: true,
          onChange: handleChangeSelectedLocation,
        }}
      />
      {selectedLocation.lat ? (
        <GoogleStaticMap
          coordinates={selectedLocation}
          title={selectedLocation?.label}
          apiKey={API_KEY}
        />
      ) : null}
    </div>
  );
};

export default LocationInput;
