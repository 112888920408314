import React from "react";
import { getLanguageObject } from "../../../../../utils/language";
import useBusinessLocation from "./hooks/useBusinessLocation";
import LocationInput from "./components/LocationInput/LocationInput";

const BusinessLocation = ({ business }) => {
  const { values, handleSubmit, handleChangeInput } = useBusinessLocation({
    business,
  });

  return (
    <div className="form-group col-md-8">
      <div className="pt-3">
        <div className="settings-form">
          <h4 className="text-primary">
            {getLanguageObject().pages.business_profile.location.title}
          </h4>
          <LocationInput
            onSelectLocation={(location) => {
              handleChangeInput(location);
              handleSubmit(location);
            }}
            defaultLocation={values}
          />
        </div>
      </div>
    </div>
  );
};

export default BusinessLocation;
