/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

const defaultLocationObject = {
  label: '',
  lat: '',
  lng: '',
};

const defaultOnChangeObject = {
  label: '',
  value: {},
};

const useLocationInput = ({ defaultLocation, onSelectLocation }) => {
  const [selectedLocation, setSelectedLocation] = useState(
    defaultLocationObject
  );

  const handleChangeSelectedLocation = (sLocation) => {
    if (sLocation) {
      setSelectedLocation(sLocation);
    } else {
      setSelectedLocation(defaultOnChangeObject);
    }
  };

  useEffect(() => {
    if (defaultLocation?.label) {
      setSelectedLocation({ ...selectedLocation, ...defaultLocation });
    } else {
      setSelectedLocation(defaultOnChangeObject);
    }
  }, [defaultLocation.label]);

  useEffect(() => {
    if (selectedLocation.label && window.google) {
      geocodeByAddress(selectedLocation.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) =>
          onSelectLocation({ lat, lng, label: selectedLocation.label })
        )
        .catch((error) => {
          console.log(error, 'geocodeByAddress');
        });
    }
  }, [selectedLocation.label]);

  return {
    selectedLocation,
    handleChangeSelectedLocation,
  };
};

export default useLocationInput;
