import Select from "react-select";
import React from "react";
import useLevelSelection from "./hooks/useLevelSelection";

const LevelSelection = ({ onSelectLevel, defaultLevel }) => {
  const { handleSelectLevel, selectedLevel, levelOptions } = useLevelSelection({
    onSelectLevel,
    defaultLevel,
  });

  return (
    <div className="col-sm-12">
      <div className="form-group">
        <Select
          isSearchable={true}
          options={levelOptions}
          className="custom-react-select"
          onChange={handleSelectLevel}
          value={selectedLevel}
        />
      </div>
    </div>
  );
};

export default LevelSelection;
