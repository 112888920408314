import React from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getLanguageObject } from "../../../../utils/language";
import { LEVELS } from "../../../../constant/global";

const MembershipCard = ({
  membership,
  handleDeleteMembership,
  handleOpenSubmitMembershipModal,
}) => {
  return (
    <Col xl={3} xxl={4} lg={4} md={6} sm={6}>
      <Card>
        <div className="card-body">
          <h4>{membership.name}</h4>
          <ul className="list-group mb-3 list-group-flush">
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">Açıklama :</span>
              <strong>{membership.description}</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">Süre :</span>
              <strong>{membership.duration} Ay</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">Seviye :</span>
              <strong>
                {membership.level ? LEVELS[membership.level] : "-"}
              </strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span>
                <i className="fa fa-money-bill text-primary me-2" />
                Fiyat
              </span>
              <strong>{membership.price} TL</strong>
            </li>
          </ul>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={handleOpenSubmitMembershipModal}
            >
              {"EDİT"}
            </button>
            <div className="icon">
              <i
                className="fa fa-trash"
                style={{ color: "#FF0000" }}
                onClick={handleDeleteMembership}
              />
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default MembershipCard;
