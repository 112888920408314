import React from "react";
import { Uploader } from "rsuite";
import { UploaderStyle } from "./FileUploader.styles";
import useFileUploader from "./hooks/useFileUploader";

const FileUploader = ({
  multiple,
  isDisabled,
  autoUpload,
  onSuccess,
  onError,
  onChange,
  fileList,
  children,
  business,
  onUpload,
}) => {
  const { fileUploadUrl, fileUploadHeaders } = useFileUploader({ business });

  return (
    <Uploader
      style={UploaderStyle}
      disabled={isDisabled}
      multiple={multiple}
      action={fileUploadUrl}
      autoUpload={autoUpload}
      fileList={fileList}
      listType="picture"
      headers={fileUploadHeaders}
      onSuccess={onSuccess}
      onError={onError}
      onChange={onChange}
      onUpload={onUpload}
    >
      {children}
    </Uploader>
  );
};

export default FileUploader;
