import React from "react";
import { Button, Modal } from "react-bootstrap";
import MembershipForm from "./components/MembershipForm";
const SubmitMembership = ({
  onClose,
  showSubmitMembershipModal,
  membershipId,
  getMemberships,
}) => {
  const modalTitle = membershipId ? "EDIT MEMBERSHIP" : "ADD MEMBERSHIP";

  return (
    <Modal
      className="modal fade"
      show={showSubmitMembershipModal}
      centered
      size="lg"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{modalTitle}</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={onClose}
          >
            <span className="font-w600">×</span>
          </Button>
        </div>

        <div className="modal-body">
          <MembershipForm
            showSubmitMembershipModal={showSubmitMembershipModal}
            membershipId={membershipId}
            onClose={onClose}
            getMemberships={getMemberships}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SubmitMembership;
