import React from "react";
import { getLanguageObject } from "../../../../../utils/language";
import { Button, Modal } from "react-bootstrap";
import PackageLessonForm from "./components/PackageLessonForm/PackageLessonForm";

const SubmitPackageLesson = ({
  packageLessonId,
  onSubmit,
  onClose,
  showPackageLessonModal,
                               getPackageLessons
}) => {
  const modalTitle = packageLessonId
    ? getLanguageObject().pages.package_lessons.modal.edit_title
    : getLanguageObject().pages.package_lessons.modal.add_title;

  return (
    <Modal className="modal fade" show={showPackageLessonModal} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{modalTitle}</h5>
          <Button
            variant=""
            type="button"
            className="close"
            data-dismiss="modal"
            onClick={onClose}
          >
            <span className="font-w600">×</span>
          </Button>
        </div>

        <div className="modal-body">
          <PackageLessonForm
            onSubmit={onSubmit}
            showPackageLessonModal={showPackageLessonModal}
            packageLessonId={packageLessonId}
            onClose={onClose}
            getPackageLessons={getPackageLessons}
          />
        </div>
      </div>
    </Modal>
  );
};

export default SubmitPackageLesson;
