import axiosInstance from "../AxiosInstance";

export function getMemberships() {
  return axiosInstance.get(`membership`);
}

export function getMembership(id) {
  return axiosInstance.get(`membership/${id}`);
}

export function createMembership(variables) {
  return axiosInstance.post(`membership`, variables);
}

export function updateMembership(id, variables) {
  return axiosInstance.put(`membership/${id}`, variables);
}

export function deleteMembership(id) {
  return axiosInstance.delete(`membership/${id}`);
}
