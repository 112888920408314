import { COMMON_URL } from "../../../../services/AxiosInstance";
import { getAuthorizationHeader } from "../../../utils/request";

const useFileUploader = ({ business = {} }) => {
  const fileUploadUrl = `${COMMON_URL}/upload?platform=business&externalId=${business.id}`;
  const fileUploadHeaders = getAuthorizationHeader();

  return { fileUploadUrl, fileUploadHeaders };
};

export default useFileUploader;
