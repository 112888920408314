import React from "react";
import usePackageLessonForm from "./hooks/usePackageLessonForm";
import BranchSelection from "../../../../../../../components/branchSelection/BranchSelection";

import { Button } from "react-bootstrap";

const PackageLessonForm = ({
  onSubmit,
  packageLessonId,
  showPackageLessonModal,
  onClose,
  getPackageLessons,
}) => {
  const {
    handleSubmit,
    handleChangeInput,
    handleSelectBranch,
    handleAddEquipment,
    values,
    buttonLabel,
  } = usePackageLessonForm({
    onSubmit,
    packageLessonId,
    showPackageLessonModal,
    onClose,
    getPackageLessons,
  });

  return (
    <form className="comment-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="branch" className="font-w600">
              {" "}
              Branch <span className="required">*</span>{" "}
            </label>
            <BranchSelection
              defaultBranchId={values.branchId}
              onSelectBranch={(selectedBranchId) => {
                handleSelectBranch(selectedBranchId);
              }}
            />
          </div>
        </div>
        <div>
          <div
            style={{
              gap: 20,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <label htmlFor="equipment" className="font-w600">
              Equipments
            </label>
            <Button
              variant="primary"
              className="btn btn-xs sharp btn-primary me-1"
              onClick={handleAddEquipment}
            >
              +
            </Button>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="name" className="font-w600">
              {" "}
              Name <span className="required">*</span>{" "}
            </label>
            <input
              type={"string"}
              className="form-control"
              value={values.name}
              onChange={(event) =>
                handleChangeInput("name", event.target.value)
              }
              placeholder={"Enter your Name"}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="preparation" className="font-w600">
              {" "}
              Preparation <span className="required">*</span>{" "}
            </label>
            <input
              type={"string"}
              className="form-control"
              value={values.preparation}
              onChange={(event) =>
                handleChangeInput("preparation", event.target.value)
              }
              placeholder={"Enter your Preparation"}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="description" className="font-w600">
              {" "}
              Description <span className="required">*</span>{" "}
            </label>
            <input
              type={"string"}
              className="form-control"
              value={values.description}
              onChange={(event) =>
                handleChangeInput("description", event.target.value)
              }
              placeholder={"Enter your Description"}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="duration" className="font-w600">
              {" "}
              Duration <span className="required">*</span>{" "}
            </label>
            <input
              type={"number"}
              className="form-control"
              value={values.duration}
              onChange={(event) =>
                handleChangeInput("duration", event.target.value)
              }
              placeholder={"Enter your Duration"}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="price" className="font-w600">
              {" "}
              Price <span className="required">*</span>{" "}
            </label>
            <input
              type={"number"}
              className="form-control"
              value={values.price}
              onChange={(event) =>
                handleChangeInput("price", event.target.value)
              }
              placeholder={"Enter your Price"}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="lessonCount" className="font-w600">
              {" "}
              Lesson Count <span className="required">*</span>{" "}
            </label>
            <input
              type={"number"}
              className="form-control"
              value={values.lessonCount}
              onChange={(event) =>
                handleChangeInput("lessonCount", event.target.value)
              }
              placeholder={"Enter your Lesson Count"}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-3">
            <label htmlFor="participantCount" className="font-w600">
              {" "}
              Participant Count <span className="required">*</span>{" "}
            </label>
            <input
              type={"number"}
              className="form-control"
              value={values.participantCount}
              onChange={(event) =>
                handleChangeInput("participantCount", event.target.value)
              }
              placeholder={"Enter your Participant Count"}
              required={true}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group mb-3">
            <input
              type="submit"
              value={buttonLabel}
              className="submit btn btn-primary"
              name="submit"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default PackageLessonForm;
