import React, { Fragment } from "react";
import SideBar from "./components/SideBar";
import NavHader from "./components/NavHader";
import Header from "./components/Header";
import ChatBox from "../ChatBox";
import useNav from "./hooks/useNav";

const Navbar = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const { onClick, toggle } = useNav();

  return (
    <Fragment>
      <NavHader />
      <ChatBox onClick={() => onClick("chatbox")} toggle={toggle} />
      <Header
        onNote={() => onClick("chatbox")}
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onBox={() => onClick("box")}
        onClick={() => ClickToAddEvent()}
      />
      <SideBar />
    </Fragment>
  );
};

export default Navbar;
