import React from "react";
import { Button, Card, Col } from "react-bootstrap";
import { getLanguageObject } from "../../../../utils/language";
import { LEVELS } from "../../../../constant/global";

const PackageLessonCard = ({
  packageLesson,
  handleOpenPackageLessonModal,
  handleDeletePackageLesson,
}) => {
  return (
    <Col xl={3} xxl={4} lg={4} md={6} sm={6}>
      <Card>
        <div className="card-body">
          <h4>{packageLesson.name}</h4>
          <ul className="list-group mb-3 list-group-flush">
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">Açıklama :</span>
              <strong
                className="text-truncate width200"
                style={{ textAlign: "end" }}
              >
                {packageLesson.description}
              </strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">Süre :</span>
              <strong>{packageLesson.duration} Dakika</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">Katılımcı sayısı :</span>
              <strong>{packageLesson.participantCount}</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">Ders Sayısı :</span>
              <strong>{packageLesson.lessonCount}</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">Seviye :</span>
              <strong>
                {packageLesson.level ? LEVELS[packageLesson.level] : "-"}
              </strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span className="mb-0">Hazırlık :</span>
              <strong>{packageLesson.preparation || "-"}</strong>
            </li>
            <li className="list-group-item px-0 d-flex justify-content-between">
              <span>
                <i className="fa fa-money-bill text-primary me-2" />
                Fiyat
              </span>
              <strong>{packageLesson.price} TL</strong>
            </li>
          </ul>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "center",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={handleOpenPackageLessonModal}
            >
              {getLanguageObject().pages.package_lessons.list.edit}
            </button>

            <div className="icon">
              <i
                className="fa fa-trash"
                style={{ color: "#FF0000" }}
                onClick={handleDeletePackageLesson}
              />
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default PackageLessonCard;
