import { useData } from "../../hooks/useData";
import { useEffect } from "react";

const useRouter = () => {
  const [business = {}, isLoading, hasError, getBusiness] = useData({
    endpoint: "/",
  });

  useEffect(() => {
    getBusiness();
  }, []);

  return { business, isLoading, hasError };
};

export default useRouter;
