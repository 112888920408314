import { useData } from "../../../hooks/useData";
import { useEffect, useState } from "react";

const useBranchSelection = ({ onSelectBranch, defaultBranchId }) => {
  const [response, isLoading, hasError, getBranches] = useData({
    fetchOnce: false,
    endpoint: "branch",
    nameSpace: "common",
  });

  const branches = response?.data || [];

  const branchOptions = branches?.map((branch) => ({
    value: branch.id,
    label: branch.name,
  }));

  useEffect(() => {
    if (branches?.length && defaultBranchId) {
      const branch = branches.find((branch) => branch.id === defaultBranchId);
      if (branch) {
        setSelectedBranch({ label: branch.name, value: branch.id });
      }
    }
  }, [defaultBranchId, branches?.length]);

  useEffect(() => {
    getBranches();
  }, []);

  const [selectedBranch, setSelectedBranch] = useState({
    value: "",
    label: "",
  });

  const handleSelectBranch = (selectedBranch) => {
    setSelectedBranch(selectedBranch);
    onSelectBranch(selectedBranch.value);
  };

  return {
    isLoading,
    hasError,
    branchOptions,
    handleSelectBranch,
    selectedBranch,
  };
};

export default useBranchSelection;
