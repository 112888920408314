import { createBusiness } from "../../../../../services/modules/BusinessService";

export const createBusinessAction = async ({
  values,
  onSuccess,
  onFailure,
}) => {
  try {
    await createBusiness(values);
    onSuccess?.();
  } catch (error) {
    onFailure?.(error);
  }
};
