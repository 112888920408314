import React from "react";

const Error = ({ description }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="form-input-content text-center error-page">
            <h4>
              <i className="fa fa-times-circle text-danger" />
            </h4>
            <p>{description || "Bir Hata Oluştu."}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error;
