import React from "react";

const Form = ({
  formInputs,
  onChangeFormInput,
  onSubmitForm,
  values,
  errors,
}) => {
  return (
    <form onSubmit={onSubmitForm}>
      {formInputs.map((formInput) => (
        <div className="mb-3">
          <label className="mb-1">
            <strong>
              {formInput.name}
              {formInput.required ? (
                <span className={"required"}>*</span>
              ) : null}
            </strong>
          </label>
          <input
            type={formInput.type}
            className="form-control"
            value={values[formInput.field]}
            onChange={(event) =>
              onChangeFormInput(formInput.field, event.target.value)
            }
            placeholder={formInput.placeholder}
            required={formInput.required}
          />
          {errors[formInput.field] && (
            <div className="text-danger fs-12">{errors[formInput.field]}</div>
          )}
        </div>
      ))}
      <div className="text-center mt-4">
        <button type="submit" className="btn btn-primary btn-block">
          Submit
        </button>
      </div>
    </form>
  );
};

export default Form;
