import { staticMapUrl } from "static-google-map";
const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

const GoogleStaticMap = ({ coordinates, title, size = "600x600" }) => {
  const url = staticMapUrl({
    key: API_KEY,
    scale: 1,
    size,
    format: "png",
    maptype: "roadmap",
    markerGroups: [
      {
        color: "red",
        label: "A",
        markers: [
          { location: { lat: coordinates.lat, lng: coordinates.lng } },
          { location: title },
        ],
      },
    ],
  });

  return (
    <>
      {url ? (
        <a href={url} rel="noreferrer" target="_blank">
          <img
            title={title}
            alt={title}
            src={url}
            width={"100%"}
            height={600}
            style={{ marginTop: 25 }}
          />
        </a>
      ) : null}
    </>
  );
};

export default GoogleStaticMap;
