import Select from "react-select";
import React from "react";
import useBranchSelection from "./hooks/useBranchSelection";

const BranchSelection = ({ onSelectBranch, defaultBranchId }) => {
  const { branchOptions, handleSelectBranch, selectedBranch } =
    useBranchSelection({ onSelectBranch, defaultBranchId });

  return (
    <div className="col-sm-12">
      <div className="form-group">
        <Select
          isSearchable={true}
          options={branchOptions}
          className="custom-react-select"
          onChange={(e) => {
            handleSelectBranch(e);
          }}
          value={selectedBranch}
        />
      </div>
    </div>
  );
};

export default BranchSelection;
