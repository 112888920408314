import { loginConfirmedAction, Logout } from "../../store/actions/AuthActions";
import axiosInstance from "../AxiosInstance";
import { LOCAL_STORAGE_KEYS } from "../../store/constants/local-storage.constants";

export function register({ email, password, firstName, lastName, phone }) {
  const postData = {
    email,
    password,
    firstName,
    lastName,
    phone,
  };

  return axiosInstance.post(`auth/register`, postData);
}

export function login({ email, password }) {
  const postData = {
    email,
    password,
  };

  return axiosInstance.post(`auth/login`, postData);
}

export function saveTokenInLocalStorage(userDetails) {
  localStorage.setItem(
    LOCAL_STORAGE_KEYS.FOR_BODY_USER,
    JSON.stringify(userDetails),
  );
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  const userDetailString = localStorage.getItem(
    LOCAL_STORAGE_KEYS.FOR_BODY_USER,
  );

  let userDetails = null;

  if (!userDetailString) {
    dispatch(Logout(navigate));
    return;
  }

  userDetails = JSON.parse(userDetailString);

  dispatch(loginConfirmedAction(userDetails));
}
