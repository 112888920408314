import { useState } from "react";
import { handleUpdateBusinessLocation } from "../../../actions/business";

const useBusinessLocation = ({ business }) => {
  const getFormValues = (business) => {
    const location = business.location || null

    return location
      ? {
          lng: business?.location?.coordinates[0],
          lat: business?.location?.coordinates[1],
          label: business.info.address,
        }
      : {
          lng: 0,
          lat: 0,
          label: "",
        };
  };

  const formValues = getFormValues(business);

  const [values, setValues] = useState(formValues);

  const handleSubmit = (location) => {
    const variables = {
      lat: location.lat,
      lng: location.lng,
      address: location.label,
    };
    handleUpdateBusinessLocation(variables);
  };

  const handleChangeInput = (value) => {
    setValues(value);
  };

  return {
    values,
    handleSubmit,
    handleChangeInput,
  };
};

export default useBusinessLocation;
