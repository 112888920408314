export const ROUTE_NAMES = {
  HOME: "home",
  PACKAGE_LESSONS: "package_lessons",
  MEMBERSHIPS: "memberships",
  EMPLOYEES: "employees",
  TRAINEES: "trainees",
  CALENDAR: "calendar",
  PURCHASES: "purchases",
  FEES: "fees",
  BUSINESS_PROFILE: "business_profile",
};

export const ROUTE_PATHS = {
  [ROUTE_NAMES.HOME]: "/",
  [ROUTE_NAMES.PACKAGE_LESSONS]: "/package-lessons",
  [ROUTE_NAMES.MEMBERSHIPS]: "/memberships",
  [ROUTE_NAMES.EMPLOYEES]: "/employees",
  [ROUTE_NAMES.TRAINEES]: "/students",
  [ROUTE_NAMES.CALENDAR]: "/calendar",
  [ROUTE_NAMES.FEES]: "/fees",
  [ROUTE_NAMES.PURCHASES]: "/purchases",
  [ROUTE_NAMES.BUSINESS_PROFILE]: "/business-profile",
};

export const ROUTE_ICONS = {
  [ROUTE_NAMES.HOME]: "la la-home",
  [ROUTE_NAMES.PACKAGE_LESSONS]: "la la-graduation-cap",
  [ROUTE_NAMES.MEMBERSHIPS]: "la la-ticket-alt",
  [ROUTE_NAMES.EMPLOYEES]: "la la-user",
  [ROUTE_NAMES.TRAINEES]: "la la-users",
  [ROUTE_NAMES.CALENDAR]: "la la-calendar",
  [ROUTE_NAMES.PURCHASES]: "la la-folder",
  [ROUTE_NAMES.FEES]: "la la-dollar",
  [ROUTE_NAMES.BUSINESS_PROFILE]: "la la-business-time",
};
