export const LANGUAGE = {
  pages: {
    common: {
      form: {
        submit_button: "Kaydet",
        cancel_button: "İptal",
      },
      pagination: {
        next_button: "İleri",
        previous_button: "Geri",
      },
      context_menu: {
        profile: "Profil",
        logout: "Çıkış Yap",
      },
      menu: {
        menu: "Menü",
        home: "Ana Sayfa",
        package_lessons: "Paket Dersler",
        memberships: "Üyelikler",
        employees: "Eğitmenler",
        trainees: "Müşteriler",
        calendar: "Takvim",
        purchases: "Satın Alımlar",
        fees: "Ödemeler",
        business_profile: "İşletme Profili",
      },
      search: "Arama",
    },
    home: {
      cards: {
        total_student_card: {
          total_student_title: "TR",
          total_student_description: "TR",
        },
        new_student_card: {
          new_student_title: "TR",
          new_student_description: "TR",
        },
        income_report_card: {
          chart_title: "TR",
        },
        teachers_list_card: {
          teacher_list_title: "TR",
          teacher_list_button: "TR",
        },
      },
    },
    memberships: {
      title: "Üyelikler",
      list: {
        title: "Tüm Üyelikler",
        duration: "TR",
        teacher: "TR",
        read_more_button: "TR",
      },
    },
    package_lessons: {
      list: {
        title: "Paket Dersler",
        add_new: "+ Yeni Paket Ekle",
        edit: "Düzenle",
        duration: "TR",
        teacher: "TR",
        read_more_button: "TR",
      },
      modal: {
        add_title: "Yeni Paket Ekle",
        edit_title: "Paket Düzenle",
      },
      detail: {
        title: "TR",
        about_course_title: "TR",
        duration: "TR",
        teacher: "TR",
        price: "TR",
        date: "TR",
        our_coursers: "TR",
        course_info: "TR",
      },
      form: {
        title: "TR",
        courses_details: "TR",
        course_name: "TR",
        course_name_placeholder: "TR",
        course_details: "TR",
        course_details_placeholder: "TR",
        start_form: "TR",
        start_form_placeholder: "TR",
        duration: "TR",
        duration_placeholder: "TR",
        price: "TR",
        price_placeholder: "TR",
        teacher: "TR",
        teacher_placeholder: "TR",
        maximum_student: "TR",
        maximum_student_placeholder: "TR",
        contact_number: "TR",
        contact_number_placeholder: "TR",
        course_photo: "TR",
      },
    },
    teachers: {
      title: "TR",
      add_button: "TR",
      list: {
        profile: "TR",
        name: "TR",
        business: "TR",
        gender: "TR",
        mobile: "TR",
        email: "TR",
        joining_date: "TR",
        rate: "TR",
        action: "TR",
      },
      detail: {
        title: "TR",
        first_name: "TR",
        last_name: "TR",
        email: "TR",
        password: "TR",
        confirm_password: "TR",
        reviews: "TR",
        rate: "TR",
        mobile: "TR",
        gender: "TR",
        business: "TR",
        date_of_birth: "TR",
        photo: "TR",
      },
      form: {
        title: "TR",
        first_name: "TR",
        last_name: "TR",
        email: "TR",
        password: "TR",
        confirm_password: "TR",
        mobile: "TR",
        gender: "TR",
        business: "TR",
        date_of_birth: "TR",
        photo: "TR",
      },
    },
    students: {
      title: "TR",
      add_button: "TR",
      list: {
        profile: "TR",
        first_name: "TR",
        last_name: "TR",
        gender: "TR",
        mobile: "TR",
        email: "TR",
        registration_date: "TR",
        action: "TR",
      },
      detail: {
        title: "TR",
        first_name: "TR",
        last_name: "TR",
        gender: "TR",
        mobile: "TR",
        email: "TR",
        registration_date: "TR",
        classes: "TR",
        date_of_birth: "TR",
        photo: "TR",
      },
      form: {
        title: "TR",
        first_name: "TR",
        last_name: "TR",
        gender: "TR",
        mobile: "TR",
        email: "TR",
        classes: "TR",
        registration_date: "TR",
        date_of_birth: "TR",
        photo: "TR",
      },
    },
    calendar: {
      title: "TR",
      today: "TR",
      month: "TR",
      week: "TR",
      day: "TR",
      description: "TR",
    },
    fees: {
      title: "TR",
      no: "TR",
      name: "TR",
      invoice_number: "TR",
      fees_type: "TR",
      payment_type: "TR",
      status: "TR",
      date: "TR",
      amount: "TR",
    },
    profile: {
      detail: {
        title: "TR",
        description: "TR",
        photos: "TR",
        address: "TR",
        contact_info: "TR",
      },
      form: {
        description: "TR",
        photos: "TR",
        address: "TR",
        contact_info: "TR",
      },
    },

    business_profile: {
      list: {
        about: "Hakkında",
      },
      business_images: {
        title: "İşletme Resimleri",
        select_files: "Dosya Seç",
        upload_button: "Yükle",
      },
      working_hours: {
        title: "Çalışma Saatleri",
        submit_button: "Kaydet",
        edit_button: "Düzenle",
      },
      about: {
        title: "İşletme Hakkında",
        name: "İşletme Adı",
        description: "Açıklama",
        type: "Tipi",
        logo: "Logo",
        submit_button: "Kaydet",
        edit_button: "Düzenle",
      },
      info: {
        title: "İşletme Bilgileri",
        address: "Adres",
        links: "Bağlantılar",
        link_name: "Platform Seçiniz",
        url: "Bağlantı",
        employees: "Personel Sayısı",
      },
      payment_info: {
        title: "Ödeme Bilgileri",
        fullname: "İsim Soyisim",
        address: "Adres",
        iban: "Iban",
        tax: "Vergilendirme",
        submit_button: "Kaydet",
        edit_button: "Düzenle",
      },
      location: {
        title: "Konum Bilgisi",
      },
    },
  },
};
