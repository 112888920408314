import React from "react";
import { Spinner } from "react-bootstrap";

const Loading = ({ description }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="form-input-content text-center error-page">
            <Spinner />
            <p className="mt-4">{description || "Yükleniyor..."}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
