import React, { useEffect, useState } from "react";
import { handleUpdateBusinessInfo } from "../../../actions/business";

const useBusinessWorkingHours = ({ business, onBusinessUpdated }) => {
  const [editWorkingHours, setEditWorkingHours] = useState(false);

  const getWorkingHourValues = (workingHours) => {
    return workingHours
      ? workingHours.map((workingHour) => {
          return {
            dayOfWeek: workingHour.dayOfWeek,
            openAt: workingHour.openAt,
            closeAt: workingHour.closeAt,
          };
        })
      : [
          {
            dayOfWeek: "",
            openAt: "",
            closeAt: "",
          },
        ];
  };

  const businessInfoValues = getWorkingHourValues(business?.info?.workingHours);

  const [workingHours, setWorkingHours] = useState(businessInfoValues);

  useEffect(() => {
    setWorkingHours(businessInfoValues);
  }, [business]);

  const handleChangeWorkingHourInput = (field, value, index) => {
    let onChangeValue = [...workingHours];
    onChangeValue[index][field] = value;
    setWorkingHours(onChangeValue);
  };

  const handleSubmit = () => {
    const variables = {
      workingHours: workingHours,
    };

    handleUpdateBusinessInfo({ variables, onSuccess: onBusinessUpdated });
  };

  return {
    handleChangeWorkingHourInput,
    workingHours,
    handleSubmit,
    editWorkingHours,
    setEditWorkingHours,
  };
};

export default useBusinessWorkingHours;
