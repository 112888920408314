import axiosInstance from "../AxiosInstance";

export function getPackages() {
  return axiosInstance.get(`package`);
}

export function getPackage(id) {
  return axiosInstance.get(`package/${id}`);
}

export function createPackage(variables) {
  return axiosInstance.post(`package`, variables);
}

export function updatePackage(id, variables) {
  return axiosInstance.put(`package/${id}`, variables);
}

export function deletePackage(id) {
  return axiosInstance.delete(`package/${id}`);
}
