import React from "react";
import Error404 from "../error/Error404";
import usePath from "../../hooks/usePath";
import { AppRoutes } from "../../router/constants/router.constants";

const NotFound = () => {
  const { path } = usePath();
  const url = AppRoutes.map((route) => route.url);

  if (url.indexOf(path) <= 0) {
    return <Error404 />;
  }

  return null;
};

export default NotFound;
