import React from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";

// image
import logoFull from "../../../../assets/images/logo-full.png";
import useRegister from "./hooks/useOnboarding";
import Form from "../../../components/form/Form";

function Register(props) {
  const {
    onboardingFormInputs,
    onSubmitBusiness,
    values,
    errors,
    handleChangeInput,
  } = useRegister();

  return (
    <div className="fix-wrapper">
      <div className="container ">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-6">
            <div className="card mb-0 h-auto">
              <div className="card-body">
                <div className="text-center mb-2">
                  <img
                    src={logoFull}
                    alt="logo"
                    style={{ height: 100, width: 100 }}
                  />
                </div>
                <h4 className="text-center mb-4 ">Create Your Business</h4>
                {props.errorMessage && (
                  <div className="text-danger">{props.errorMessage}</div>
                )}
                <Form
                  formInputs={onboardingFormInputs}
                  onChangeFormInput={handleChangeInput}
                  onSubmitForm={onSubmitBusiness}
                  values={values}
                  errors={errors}
                />
                <div className="new-account mt-3">
                  <button className="btn text-decoration-underline text-center btn-block">
                    Logout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
