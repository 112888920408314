import { getLanguageObject } from "../../../utils/language";
import { ROUTE_ICONS, ROUTE_NAMES, ROUTE_PATHS } from "../../../constant/route";

export const MenuList = [
  {
    title: getLanguageObject().pages.common.menu.menu,
    classsChange: "menu-title",
    extraclass: "first",
  },
  ...Object.values(ROUTE_NAMES).map((routeName) => ({
    title: getLanguageObject().pages.common.menu[routeName],
    to: ROUTE_PATHS[routeName],
    iconStyle: <i className={ROUTE_ICONS[routeName]} />,
  })),
];
