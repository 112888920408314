import axios from "axios";
import { getAuthorizationHeader } from "../jsx/utils/request";

export const BASE_URL = "https://api.forbody.app/business";
export const COMMON_URL = "https://api.forbody.app/common";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const commonInstance = axios.create({
  baseURL: COMMON_URL,
});

commonInstance.interceptors.request.use((config) => {
  config.headers = { ...config.headers, ...getAuthorizationHeader() };

  return config;
});

axiosInstance.interceptors.request.use((config) => {
  config.headers = { ...config.headers, ...getAuthorizationHeader() };

  return config;
});

export default axiosInstance;
