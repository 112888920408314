import React from 'react';
import PageTitle from '../../../layouts/PageTitle';
import CalendarBlog from '../../holidays/CalendarBlog';

const EventManagement = () => {
    return (
        <>
            <PageTitle activeMenu={"Calendar"} motherMenu={"Apps"}/>
            <CalendarBlog />
        </>
    );
};

export default EventManagement;
