import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../../context/ThemeContext";
import forBodyLogo from "../../../../assets/images/forBody-logo.png";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);
  console.log(background, navigationHader);
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <div className="d-flex align-items-center gap-2">
              <img
                src={forBodyLogo}
                alt="logo"
                className="logo-abbr"
                width="50"
                height="50"
              />
              <span
                className="brand-title"
                style={{ fontSize: 30, color: "white" }}
              >
                forBody
              </span>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="d-flex align-items-center gap-2">
              <img
                src={forBodyLogo}
                alt="logo"
                className="logo-abbr"
                width="50"
                height="50"
              />
              <span
                className="brand-title"
                style={{ fontSize: 30, color: "white" }}
              >
                forBody
              </span>
            </div>
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
