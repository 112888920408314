import { useState } from "react";

const useNav = () => {
  const [toggle, setToggle] = useState("");

  const onClick = (name) => setToggle(toggle === name ? "" : name);

  return { onClick, toggle };
};

export default useNav;
