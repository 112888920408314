import React from "react";
import useBusinessWorkingHours from "./hooks/useBusinessWorkingHours";
import { getLanguageObject } from "../../../../../utils/language";
import { getDay } from "../../utils/business-profile.utils";

const BusinessWorkingHours = ({ business, onBusinessUpdated }) => {
  const {
    handleChangeWorkingHourInput,
    workingHours,
    handleSubmit,
    editWorkingHours,
    setEditWorkingHours,
  } = useBusinessWorkingHours({ business, onBusinessUpdated });

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header border-0 pb-0">
          <h5 className="text-primary">
            {getLanguageObject().pages.business_profile.working_hours.title}
          </h5>
          {editWorkingHours ? (
            <button
              className="btn btn-primary"
              onClick={() => {
                handleSubmit();
                setEditWorkingHours(false);
              }}
            >
              {
                getLanguageObject().pages.business_profile.working_hours
                  .submit_button
              }
            </button>
          ) : (
            <button
              className="btn btn-primary"
              onClick={() => setEditWorkingHours(true)}
            >
              {
                getLanguageObject().pages.business_profile.working_hours
                  .edit_button
              }
            </button>
          )}
        </div>
        <div className="card-body pt-3">
          <div className="profile-news">
            <div>
              {workingHours?.map((workingHour, index) => {
                return (
                  <div className="row mb-2" key={index}>
                    <div className="col-4">
                      {editWorkingHours ? (
                        <input
                          type="text"
                          disabled
                          value={getDay(workingHour.dayOfWeek)}
                          onChange={(e) =>
                            handleChangeWorkingHourInput(
                              "dayOfWeek",
                              e.target.value,
                              index,
                            )
                          }
                          className="form-control"
                        />
                      ) : (
                        <h6>{getDay(workingHour.dayOfWeek)}</h6>
                      )}
                    </div>

                    <div className="col-4">
                      {editWorkingHours ? (
                        <input
                          type="time"
                          value={workingHour.openAt}
                          onChange={(e) =>
                            handleChangeWorkingHourInput(
                              "openAt",
                              e.target.value,
                              index,
                            )
                          }
                          className="form-control"
                        />
                      ) : (
                        <p>{workingHour.openAt}</p>
                      )}
                    </div>

                    <div className="col-4">
                      {editWorkingHours ? (
                        <input
                          type="time"
                          value={workingHour.closeAt}
                          onChange={(e) =>
                            handleChangeWorkingHourInput(
                              "closeAt",
                              e.target.value,
                              index,
                            )
                          }
                          className="form-control"
                        />
                      ) : (
                        <p>{workingHour.closeAt}</p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessWorkingHours;
