import React from "react";

const Empty = ({ description }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="form-input-content text-center error-page">
            <h4>
              <i className="fa fa-exclamation-triangle text-warning" />
            </h4>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Empty;
