import React from "react";
import { Col, Nav, Tab } from "react-bootstrap";
import { IMAGES } from "../../../../constant/theme";

const salaryTable = [
  {
    image: IMAGES.education2,
    name: "Angelica Ramos",
    color: "success",
    status: "Paid",
    date: "12 Jan 2024",
    amount: "100",
    transId: "42317",
  },
  {
    image: IMAGES.education1,
    name: "Cedric Kelly",
    color: "danger",
    status: "Unpaid",
    date: "07 Jan 2024",
    amount: "200",
    transId: "13369",
  },
  {
    image: IMAGES.education4,
    name: "Bradley Greer",
    color: "warning",
    status: "Pending",
    date: "08 Jan 2024",
    amount: "150",
    transId: "25413",
  },
  {
    image: IMAGES.education3,
    name: "Rhona Davidson",
    color: "danger",
    status: "Unpaid",
    date: "02 Jan 2024",
    amount: "250",
    transId: "74125",
  },
  {
    image: IMAGES.education5,
    name: "Caesar Vance",
    color: "success",
    status: "Paid",
    date: "10 Jan 2024",
    amount: "300",
    transId: "23654",
  },
];

const SalaryStatus = () => {
  return (
    <Col xl={"12"} lg={"12"} xxl={"12"} md={"12"}>
      <div className="card profile-tab">
        <div className="card-header">
          <h4 className="card-title">Salary Status</h4>
        </div>
        <div className="card-body custom-tab-1">
          <Tab.Container defaultActiveKey="Posts">
            <Nav as="ul" className="nav-tabs mb-3">
              <Nav.Item as="li">
                <Nav.Link eventKey="Purchases" className="pb-2">
                  Purchases
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Nav.Link eventKey="Fees" className="pb-2">
                  Fees
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={"Purchases"}>
                <div className="table-responsive">
                  <table className="table table-responsive-md text-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">Profile</th>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Transaction ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salaryTable.map((item, ind) => (
                        <tr key={ind}>
                          <td>
                            <img
                              src={item.image}
                              className="rounded-circle"
                              width="35"
                              alt=""
                            />
                          </td>
                          <td>{item.name}</td>
                          <td>
                            <span
                              className={`badge badge-rounded badge-${item.color}`}
                            >
                              {item.status}
                            </span>
                          </td>
                          <td>{item.date}</td>
                          <td>${item.amount}</td>
                          <td>#{item.transId}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey={"Fees"}>
                <div className="table-responsive">
                  <table className="table table-responsive-md text-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">Profile</th>
                        <th scope="col">Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Transaction ID</th>
                      </tr>
                    </thead>
                    <tbody>
                      {salaryTable.slice(1, 3).map((item, ind) => (
                        <tr key={ind}>
                          <td>
                            <img
                              src={item.image}
                              className="rounded-circle"
                              width="35"
                              alt=""
                            />
                          </td>
                          <td>{item.name}</td>
                          <td>
                            <span
                              className={`badge badge-rounded badge-${item.color}`}
                            >
                              {item.status}
                            </span>
                          </td>
                          <td>{item.date}</td>
                          <td>${item.amount}</td>
                          <td>#{item.transId}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </Col>
  );
};

export default SalaryStatus;
