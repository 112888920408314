import { useState } from "react";
import { handleUpdateBusinessPaymentInfo } from "../../../actions/business";

const usePaymentInfo = ({ business, onBusinessUpdated }) => {
  const getFormValues = (paymentInfo) => {
    return paymentInfo
      ? {
          fullName: paymentInfo.fullName,
          address: paymentInfo.address,
          iban: paymentInfo.iban,
          tax: paymentInfo.tax,
        }
      : {
          fullName: "",
          address: "",
          iban: "",
          tax: "",
        };
  };

  const formValues = getFormValues(business.paymentInfo);

  const [values, setValues] = useState(formValues);

  const [isDisabled, setIsDisabled] = useState(true);

  const handleChangeInput = (field, value) => {
    setValues({ ...values, [field]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const variables = {
      fullName: values.fullName,
      address: values.address,
      iban: values.iban,
      tax: values.tax,
    };

    handleUpdateBusinessPaymentInfo({
      variables,
      onSuccess: onBusinessUpdated,
    });
  };

  return { values, handleChangeInput, handleSubmit, isDisabled, setIsDisabled };
};

export default usePaymentInfo;
