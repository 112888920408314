import React, { useMemo, useState } from "react";
import Loading from "../../../../components/loading/Loading";
import Error from "../../../../components/error/Error";
import Empty from "../../../../components/empty/Empty";
import MembershipCard from "../components/MembershipCard";
import { useData } from "../../../../hooks/useData";
import { deleteMembership } from "../../../../../services/modules/MembershipService";

const useMemberships = () => {
  const [memberships = [], isLoading, hasError, getMemberships] = useData({
    endpoint: "membership",
  });

  const defaultSubmitMembershipModalState = {
    visible: false,
    membershipId: null,
  };

  const [submitMembershipModalState, setSubmitMembershipModalState] = useState(
    defaultSubmitMembershipModalState,
  );

  const handleOpenSubmitMembershipModal = (membershipId = null) => {
    setSubmitMembershipModalState({ membershipId, visible: true });
  };

  const handleCloseMembershipModal = () => {
    setSubmitMembershipModalState(defaultSubmitMembershipModalState);
  };

  const refreshMemberships = () => {
    getMemberships();
  };

  const handleDeleteMembership = (id) => {
    deleteMembership(id).then((response) => {
      if (response.status === 200) {
        refreshMemberships();
      }
    });
  };

  const Content = useMemo(() => {
    if (isLoading) {
      return <Loading />;
    }

    if (hasError) {
      return <Error description={"Üyelikleri listelerken bir hata oluştu."} />;
    }

    const isEmpty = !memberships.length;

    if (isEmpty) {
      return <Empty description={"Üyelik bulunamadı."} />;
    }

    return memberships.map((membership, index) => (
      <MembershipCard
        membership={membership}
        key={index}
        handleOpenSubmitMembershipModal={()=>{handleOpenSubmitMembershipModal(membership.id)}}
        handleDeleteMembership={() => {
          handleDeleteMembership(membership.id);
        }}
      />
    ));
  }, [hasError, isLoading, memberships]);

  const submitMembershipModalProps = {
    onClose: handleCloseMembershipModal,
    showSubmitMembershipModal: submitMembershipModalState.visible,
    membershipId: submitMembershipModalState.membershipId,
    getMemberships: getMemberships,
  };

  return {
    Content,
    handleOpenSubmitMembershipModal,
    submitMembershipModalProps,
  };
};

export default useMemberships;
